<template>
  <v-container
    class="ma-0 pa-0"
    style="width:100%;max-width: 3000px;overflow:auto;"
  >
    <v-row class="ma-0 pa-0">
      <!-- {{receipts}} -->
      <v-col class="ma-0 pa-0">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="displayItemsAfterFilter"
          :footer-props="{
            'items-per-page-options': [30, 50, 100, -1],
          }"
          :search="searchString"
          :loading="loading"
          loading-text="Loading... Please wait"
          dense
          item-key="receipt_number"
          sort-by="receipt_number"
          :sort-desc="true"
          :multi-sort="false"
          style="padding:6px;"
          :mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-card flat>
              <v-card-title class="pt-0">
                <v-btn class="mb-2" dark color="green" @click="exportExcel">
                  <v-icon class="mr-2 ">
                    mdi-download
                  </v-icon>
                  Xuất
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mb-2"
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  label="Search"
                  outlined
                  single-line
                  hide-details
                  clearable
                  dense
                  style="max-width:300px;"
                ></v-text-field>
              </v-card-title>
            </v-card>
          </template>
          <template v-slot:item.receipt_number="{ item }">
            <a
              @click="
                showDetailItem = true;
                detailItem = item;
              "
              style="text-decoration: underline;"
            >
              {{ item.receipt_number }}
            </a>
          </template>
          <template v-slot:item.item_text="{ item }">
            <div v-if="item.line_items">
              <div v-for="(line_item, dIndex) in item.line_items" :key="dIndex"
                style="font-size:13px;line-height:1.3;">
                • {{line_item.item_name}} (x{{line_item.quantity}})
              </div>
            </div>
          </template>
          <template v-slot:item.discount_total="{ item }">
            <div v-if="item.total_discounts">
              <span v-for="(discount, dIndex) in item.total_discounts" :key="dIndex"
                class="ml-2">
                {{discount.money_amount.toLocaleString()}}
              </span>
            </div>
          </template>
          <template v-slot:item.discount_texts="{ item }">
            {{item.discount_texts}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Detail Item -->
    <v-dialog
      v-model="showDetailItem"
      max-width="50vw"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <dialog-report-detail
        :item="detailItem"
        @save-success="showDetailItem = false"
        @cancel="showDetailItem = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
let moment = require("moment");
let XLSX = require("xlsx");
import { mapGetters } from "vuex";
import DialogReportDetail from "@/components/Loyverse/DialogReportDetail";

export default {
  props: ["employeesFilterArr", "customersFilterArr", "params"],
  data() {
    return {
      // items: require("@/mkdata/loyverse_report").data.receipts,
      receipts: null,
      employees: null,
      customers: null,
      pos_devices: null,
      searchString: "",
      loading: false,
      selected: [],
      itemsSelected: [],
      headers: [
        {
          text: "Số hóa đơn",
          value: "receipt_number",
          align: "start",
          class: " font-weight-bold dateMinWidth",
          sortable: true,
          width: "90",
        },
        {
          text: "Thời gian",
          value: "receipt_dateTxt",
          align: "start",
          class: " font-weight-bold lighten-1",
          sortable: true,
          width: "150",
        },
        // {
        //   text: "Nhân viên",
        //   value: "employee_name",
        //   align: "start",
        //   class: " font-weight-bold lighten-1",
        //   sortable: true,
        //   width: "200",
        // },
        {
          text: "Khách hàng",
          value: "customer_name",
          align: "start",
          class: "levelMinWidth",
          sortable: true,
        },
        {
          text: "Loại",
          value: "receipt_typeTxt",
          align: "start",
          class: " font-weight-bold",
          sortable: false,
        },
        {
          text: "Món ăn",
          value: "item_text",
          align: "start",
          class: " font-weight-bold",
          sortable: false,
          width: "240",
        },
        {
          text: "Giảm giá",
          value: "discount_total",
          align: "end",
          class: " font-weight-bold",
          sortable: false,
        },
        {
          text: "Loại giảm giá",
          value: "discount_texts",
          align: "end",
          class: " font-weight-bold",
          sortable: false,
        },
        {
          text: "Tổng",
          value: "total_money",
          align: "end",
          class: " font-weight-bold",
          sortable: false,
        }
      ],
      detailItem: null,
      showDetailItem: false,
      numOfDiscount: 0,
    };
  },
  components: {
    DialogReportDetail,
  },
  watch: {
    params() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "role",
      fullname: "getFullName",
    }),
    displayItemsAfterFilter() {
      let result = [];
      this.processAfterFetchDataReceipts();
      // this.processAfterFetchDataEmplyees();
      // this.processAfterFetchDataCustomers();
      if (!this.receipts) return [];
      result = JSON.parse(JSON.stringify(this.receipts));
      //Filter by Employees
      if (this.employeesFilterArr.length > 0) {
        result = result.filter((i) =>
          this.employeesFilterArr.map((e) => e.id).includes(i.employee_id)
        );
      }
      //Filter by Customers
      if (this.customersFilterArr.length > 0) {
        console.log("Filter By Customer", this.customersFilterArr);
        result = result.filter((i) =>
          this.customersFilterArr.map((e) => e.id).includes(i.customer_id)
        );
        console.log(result);
      }
      this.processTotalReport(result);
      return result;
    },
  },
  methods: {
    processAfterFetchDataReceipts() {
      console.log("processAfterFetchDataReceipts");
      if (this.receipts && this.receipts.length > 0) {
        this.receipts.forEach((item) => {
          item.receipt_dateTxt = moment(item.receipt_date).format(
            "DD/MM/YYYY HH:mm"
          );
          if (item.receipt_number == "1-4515")
            console.log(item.receipt_dateTxt);

          switch (item.receipt_type) {
            case "SALE":
              item.receipt_typeTxt = "Bán hàng";
              break;
            case "REFUND":
              item.receipt_typeTxt = "Hoàn tiền";
              break;
            default:
              item.receipt_typeTxt = "";
              break;
          }
          // //Employees
          // if (!this.employees.find((i) => i.id == item.employee_id)) {
          //   item.employee_name = "";
          // } else {
          //   item.employee_name = this.employees.find(
          //     (i) => i.id == item.employee_id
          //   ).name;
          // }
          // //Customer
          // if (!this.customers.find((i) => i.id == item.customer_id)) {
          //   item.customer_name = "-";
          // } else {
          //   item.customer_name = this.customers.find(
          //     (i) => i.id == item.customer_id
          //   ).name;
          // }

          // //pos_devices
          // if (!this.pos_devices.find((i) => i.id == item.pos_device_id)) {
          //   item.pos_device_name = "";
          // } else {
          //   item.pos_device_name = this.pos_devices.find(
          //     (i) => i.id == item.pos_device_id
          //   ).name;
          // }
        });
      }
    },
    // processAfterFetchDataEmplyees() {
    //   if (this.receipts && this.receipts.length > 0) {
    //     this.receipts.forEach((item) => {
    //       if (!this.employees.find((i) => i.id == item.employee_id)) {
    //         item.employee_name = "";
    //       } else {
    //         item.employee_name = this.employees.find(
    //           (i) => i.id == item.employee_id
    //         ).name;
    //       }
    //     });
    //   }
    // },
    // processAfterFetchDataCustomers() {
    //   if (this.receipts && this.receipts.length > 0) {
    //     this.receipts.forEach((item) => {
    //       if (!this.customers.find((i) => i.id == item.customer_id)) {
    //         item.customer_name = "-";
    //       } else {
    //         item.customer_name = this.customers.find(
    //           (i) => i.id == item.customer_id
    //         ).name;
    //       }
    //     });
    //   }
    // },
    fetchAllDataReceiptsAfter() {
      var self = this;
      let pathFetchDataReceipts = self.$root.apiLoyverse + "/receipts/alls";
      let paramsReceipts = {
        created_at_min: moment(self.params.timeRange.start).toISOString(),
        created_at_max: moment(self.params.timeRange.end).toISOString(),
      };

      self.loading = true;
      self.axios
        .get(pathFetchDataReceipts, { params: paramsReceipts })
        .then((response) => {
          if (response.data.status == "OK") {
            self.receipts = response.data.content;
          }
        })
        .catch((err) => {
          console.log("err", err.message);
        });
    },
    fetchAllDataCustomersAfter() {
      var self = this;
      let pathFetchDataCustomers = self.$root.apiLoyverse + "/customers/alls";

      self.loading = true;
      self.axios
        .get(pathFetchDataCustomers)
        .then((response) => {
          if (response.data.status == "OK") {
            self.customers = response.data.content;
          }
        })
        .catch((err) => {
          console.log("err", err.message);
        });
    },
    fetchData() {
      var self = this;
      self.receipts = [];
      self.employees = [];
      self.customers = [];
      self.pos_devices = [];
      let pathFetchDataReceipts = self.$root.apiUser + "/receipts/all";
      let paramsReceipts = {
        created_at_min: moment(self.params.timeRange.start).toISOString(),
        created_at_max: moment(self.params.timeRange.end).toISOString(),
      };
      // let pathFetchDataEmployees = self.$root.apiLoyverse + "/employees/all";
      // let pathFetchDataCustomers = self.$root.apiLoyverse + "/customers/all";
      // let pathFetchDataPosDevices = self.$root.apiLoyverse + "/pos_devices/all";

      self.loading = true;
      Promise.all([
        self.axios.get(pathFetchDataReceipts, { params: paramsReceipts }),
        // self.axios.get(pathFetchDataEmployees),
        // self.axios.get(pathFetchDataCustomers),
        // self.axios.get(pathFetchDataPosDevices),
      ])
        .then((responseArray) => {
          console.log("Promise ALL");
          if (responseArray[0].data.status == "OK") {
            self.receipts = responseArray[0].data.content;
            if (responseArray[0].data.content.cursor) {
              this.fetchAllDataReceiptsAfter();
            }
          }
          // if (responseArray[1].data.status == "OK") {
          //   self.employees = responseArray[1].data.content;
          // }
          // if (responseArray[2].data.status == "OK") {
          //   self.customers = responseArray[2].data.content.customers;
          //   if(responseArray[2].data.content.cursor){
          //     this.fetchAllDataCustomersAfter()
          //   }
          // }
          // if (responseArray[3].data.status == "OK") {
          //   self.pos_devices = responseArray[3].data.content;
          // }
        })
        .finally(() => {
          // self.processTotalReport();
          self.loading = false;
        });
    },
    processTotalReport(reciepts) {
      reciepts.forEach(receipt => {
        receipt.item_text = ""
        receipt.discount_total = 0
        receipt.discount_texts = ""
        receipt.line_items.forEach(line_item => {
          receipt.item_text += `${line_item.item_name} (x${line_item.quantity}), `
        })
        receipt.total_discounts.forEach(discount => {
          receipt.discount_total += discount.money_amount
          receipt.discount_texts += `${discount.name}, `
        })

      })

      let self = this
      // let reciepts = JSON.parse(JSON.stringify(self.displayItemsAfterFilter));
      let totalReciept = reciepts.length
      let totalRecieptMoney = 0
      let totalSalesMoney = 0
      let totalRefundMoney = 0
      let totalDiscount = 0
      let numOfDiscount = 0

      if (totalReciept > 0) {
        totalRecieptMoney = reciepts
          .map((el) => el.total_money)
          .reduce((accumulator, currentValue) => accumulator + currentValue)
      }
      let totalSales =
        totalReciept > 0
          ? reciepts.filter((i) => i.receipt_type == "SALE").length
          : 0;
      if (totalSales > 0) {
        totalSalesMoney = reciepts
          .filter((i) => i.receipt_type == "SALE")
          .map((el) => el.total_money)
          .reduce((accumulator, currentValue) => accumulator + currentValue);
      }

      let totalRefund =
        totalReciept > 0
          ? reciepts.filter((i) => i.receipt_type == "REFUND").length
          : 0;
      if (totalRefund > 0) {
        totalRefundMoney = reciepts
          .filter((i) => i.receipt_type == "REFUND")
          .map((el) => el.total_money)
          .reduce((accumulator, currentValue) => accumulator + currentValue);
      }

      reciepts.forEach(receipt => {
        if(receipt.total_discounts) {
          receipt.total_discounts.forEach(discount => {
            numOfDiscount++
            totalDiscount += discount.money_amount
          })
        }
      })

      self.$emit("report-result", {
        totalReciept,
        totalSales,
        totalRefund,
        totalRecieptMoney,
        totalSalesMoney,
        totalRefundMoney,
        numOfDiscount,
        totalDiscount,
      });
    },
    exportExcel() {
      let wb = XLSX.utils.book_new();
      let data = [];
      let header = this.headers.map((theader) => theader.text);
      let dataExport = this.displayItemsAfterFilter;
      dataExport.forEach((order) => {
        let returnRow = {};
        returnRow[header[0]] = order["receipt_number"];
        returnRow[header[1]] = order["receipt_dateTxt"].toLocaleString();
        returnRow[header[2]] = order["customer_name"].toLocaleString();
        returnRow[header[3]] = order["receipt_typeTxt"].toLocaleString();
        returnRow[header[4]] = order["item_text"].toLocaleString();
        returnRow[header[5]] = order["discount_total"].toLocaleString();
        returnRow[header[6]] = order["discount_texts"].toLocaleString();
        returnRow[header[7]] = order["total_money"].toLocaleString();
        data.push(returnRow);
      });

      let dataWS = XLSX.utils.json_to_sheet(data, {
        header: header,
      });
      let wscols = [{ wpx: 140 }];
      header.forEach((f, index) => {
        if (index > 0) wscols.push({ wpx: 120 });
      });
      dataWS["!cols"] = wscols;
      XLSX.utils.book_append_sheet(wb, dataWS, "Report");
      const fileName = this.genExcelFileName();
      XLSX.writeFile(wb, fileName);
    },
    genExcelFileName() {
      let filename = "Hoa don";
      filename =
        filename +
        " - " +
        this.$moment(this.params.timeRange.start).format("DD-MM-YYYY") +
        " den " +
        this.$moment(this.params.timeRange.end).format("DD-MM-YYYY") +
        ".xlsx";
      return filename;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  },
};
</script>

<style>
#alarmHistoryTable div table tbody tr td {
  margin: 0px;
  height: 34px;
  line-height: 34px;
}
.dateMinWidth {
  min-width: 164px;
}
.levelMinWidth {
  min-width: 80px;
}
</style>
