var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%","max-width":"3000px","overflow":"auto"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-data-table',{staticStyle:{"padding":"6px"},attrs:{"headers":_vm.headers,"items":_vm.displayItemsAfterFilter,"footer-props":{
          'items-per-page-options': [30, 50, 100, -1],
        },"search":_vm.searchString,"loading":_vm.loading,"loading-text":"Loading... Please wait","dense":"","item-key":"receipt_number","sort-by":"receipt_number","sort-desc":true,"multi-sort":false,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-btn',{staticClass:"mb-2",attrs:{"dark":"","color":"green"},on:{"click":_vm.exportExcel}},[_c('v-icon',{staticClass:"mr-2 "},[_vm._v(" mdi-download ")]),_vm._v(" Xuất ")],1),_c('v-spacer'),_c('v-text-field',{staticClass:"mb-2",staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1)],1)]},proxy:true},{key:"item.receipt_number",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.showDetailItem = true;
              _vm.detailItem = item;}}},[_vm._v(" "+_vm._s(item.receipt_number)+" ")])]}},{key:"item.item_text",fn:function(ref){
              var item = ref.item;
return [(item.line_items)?_c('div',_vm._l((item.line_items),function(line_item,dIndex){return _c('div',{key:dIndex,staticStyle:{"font-size":"13px","line-height":"1.3"}},[_vm._v(" • "+_vm._s(line_item.item_name)+" (x"+_vm._s(line_item.quantity)+") ")])}),0):_vm._e()]}},{key:"item.discount_total",fn:function(ref){
              var item = ref.item;
return [(item.total_discounts)?_c('div',_vm._l((item.total_discounts),function(discount,dIndex){return _c('span',{key:dIndex,staticClass:"ml-2"},[_vm._v(" "+_vm._s(discount.money_amount.toLocaleString())+" ")])}),0):_vm._e()]}},{key:"item.discount_texts",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount_texts)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"50vw","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.showDetailItem),callback:function ($$v) {_vm.showDetailItem=$$v},expression:"showDetailItem"}},[_c('dialog-report-detail',{attrs:{"item":_vm.detailItem},on:{"save-success":function($event){_vm.showDetailItem = false},"cancel":function($event){_vm.showDetailItem = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }